// index.scss

/*
 * IMPORTS
 */

@import "_modal.scss";
@import "_utilities.scss";

/*
 * VARIABLES
 */

$header_height: 60px;
$footer_height: 90px;

/* 
 * GENERAL STYLES 
 */

body { 
  margin: 0; padding: 0; 
}


/* 
 * HEADER 
 */

div#header {
  height: $header_height;  
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a#logo {
    text-decoration: none;
    display: inherit;
    img {
      height: 50px;
      padding: 5px 10px;
    }
    &:hover {
      filter: brightness(115%);
    }
  }

  // directions control styles 
  div.trail-directions-control {
    font-family: 'Lato', sans-serif;
    width: auto;
    display: block;
    padding: 0;
    outline: none;
    border: 0;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;
    text-align: center;
    cursor: default;

    span.trail-directions-btn {
      display: inline-block;
      font-weight: 700;
      background: #e94820;
      color: #fff;
      width: auto;
      margin-right: 7px;
      height: 25px;
      line-height: 1.9;
      border-radius: 3px;
      padding: 4px 10px;
      font-size: 13.5px;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        filter: brightness(115%);
      }
    }

    div#clearlink {
      display: none;
      padding: 0.4em;
      width: 110px;
      margin: 0 auto;
      text-transform: uppercase;
      font-size: 11px;
      border-radius: 4px;
      text-decoration: underline;
      cursor: pointer;
      color: #777;
      &:hover {
        color: #444;
      }
    }
  }
}

/* 
 * FOOTER 
 */

 div#footer {
  position: absolute;
  height: $footer-height;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  div.swiper-container {
    text-align: center;
    a {
      text-decoration: none;
      cursor: pointer;
      img {
        height: $footer-height - 10;
        position: relative;
      }
    }
  }

}

/* 
 * MAP AND MARKER STYLES 
 */
#map { 
  position: absolute; 
  top: $header_height; 
  bottom: $footer_height; 
  width: 100%; 
}

.marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  &:hover {
    filter: brightness(115%);
  }
}

// not showing this button at all
.mapboxgl-ctrl-directions {
  margin: 0 !important;
}

// zoom home control styles
.zoom-home-control {
  width: 29px;
  height: 29px;
  display: block;
  padding: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;

  span.zoom-home-icon {
    display: block;
    background-image: url(../static/icons/zoomhome.svg);
    background-position: 50% 2px;
  }
}


/* 
 * MAP POPUP STYLES
 */ 


.mapboxgl-popup {
  z-index: 1;

  .mapboxgl-popup-content {
    font-family: 'Lato', sans-serif;
    min-width: 210px;
    a.header-link {
      text-decoration: none;
      display: inline-block;
      margin: 10px 0 8px;
      max-width: 85%;
    }
    h3.popup-header {
      color: #1b7c8a;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 700;
      display: inline-block;
      line-height: 1.3;
      margin: 0;

      &.access-header {
        max-width: 85%;
      }

      &:hover {
        filter: brightness(50%);
      }
      span.outlink {
        background-image: url(../static/icons/external-link.svg);
        background-size: 17px;
        background-repeat: no-repeat;
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-left: 7px;
      }
    }
    .description {
      color: #333;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
    }
    div#popup-footer {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      a.popup-footer-website-link {
        color: #1b7c8a;
        display: inline-block;
        text-transform: uppercase;
        padding: 0.8em 0;
      }
      a.popup-footer-directions {
        float: right;
        text-transform: uppercase;
        text-decoration: none;
        color: #fff;
        background: #e94820;
        padding: 0.8em;
        border-radius: 3px;

        &:hover {
          filter: brightness(115%);
        }      
      }
    }
    button.mapboxgl-popup-close-button {
      font-size: 1.5rem;
      padding-left: 0;
      padding-right: 8px;
    }
  }
}

/* 
 * MODAL CONTENT (see _modal.scss for general modal styles)
 */ 

#modal-splash {
  
  header.modal__header {
    background: orange !important;
    margin: -2em !important;
    padding: 2em !important;
  

    h2.modal__title {
      color: #fff;
      font-size: 1.65rem;
    }

    button.modal__close {
      color: #fff;
    }

  }

  .mapboxgl-ctrl {
    width: 29px;
    height: 29px;
    display: inline-block;
    cursor: default;

    button {
      width: 29px;
      height: 29px;
      padding: 0;
    }
  }
}


