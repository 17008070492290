// _utilities.scss



.text-bold {
  font-weight: 600;
}

.text-italicized {
  font-style: italic;
}

.mr-1 {
  margin-right: .25rem;
} 
.mr-2 {
  margin-right: .5rem;
} 

.ml-1 {
  margin-left: .25rem;
} 
.ml-2 {
  margin-left: .5rem;
} 

.mt-1 {
  margin-top: .25rem;
} 
.mt-2 {
  margin-top: .5rem;
} 
.mt-3 {
  margin-top: 1rem;
} 
.mt-4 {
  margin-top: 1.5rem;
} 
.mt-5 {
  margin-top: 3rem;
} 

.mb-1 {
  margin-bottom: .25rem;
} 
.mb-2 {
  margin-bottom: .5rem;
} 
.mb-3 {
  margin-bottom: 1rem;
} 
.mb-4 {
  margin-bottom: 1.5rem;
} 
.mb-5 {
  margin-bottom: 3rem;
} 

.pt-1 {
  padding-top: .25rem;
} 
.pt-2 {
  padding-top: .5rem;
} 
.pt-3 {
  padding-top: 1rem;
} 
.pt-4 {
  padding-top: 1.5rem;
} 
.pt-5 {
  padding-top: 3rem;
} 


.pb-1 {
  padding-bottom: .25rem;
} 
.pb-2 {
  padding-bottom: .5rem;
} 
.pb-3 {
  padding-bottom: 1rem;
} 
.pb-4 {
  padding-bottom: 1.5rem;
} 
.pb-5 {
  padding-bottom: 3rem;
} 

