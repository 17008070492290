// _modal.scss

.modal {
  font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:10;
}

.modal__container {
  background-color: #fff;
  padding: 2em;
  max-width: 740px;
  height: 100%;
  width: 50%;
  border-radius: 3px;
  box-sizing: border-box;
}

@supports (display: flex) {
  
  .modal__container {
    height: initial;
    max-height: 80vh;
  }
  
}

.modal__header {
  position: relative;
  display: block;
  height: 30px;
  margin-bottom: 20px;
}

@supports (display: flex) {
  
  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:initial;
    margin-bottom: 0px;
  }
  
}

.modal__title {
  position: absolute;
  top: 20px;
  left: 20px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: 0;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

@supports (display: flex) {
  
  .modal__title {
    position: static;
  }
  
  .modal__close {
    position: static;
  }
  
}

.modal__header .modal__close:before { content: "\2715"; }

.modal__content {
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(0,0,0,.8);
}

@supports (display: flex) {
  
  .modal__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.4;
  }

}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

/**************************
  Demo Animation Style
**************************/

@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

/**************************
  Custom styles for individual modals
**************************/

.modal__container button {
  outline: none;
  cursor: pointer !important;
}

.modal__container h2.modal__title {
  color: #595959;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  font-size: 24px;
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.4;
  color: rgba(0,0,0,.8);
}

.modal__btn {
  padding: 10px 15px;
  background-color: #e6e6e6;
  border-radius: 4px;
  -webkit-appearance: none;
}

/**************************
  Mobile custom styles for individual modals
**************************/

@media only screen and (min-width : 320px) and (max-width : 480px) {
  .modal__container {
    width: 90% !important;
    min-width: 90% !important;
  }
}